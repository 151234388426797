import React from "react"
import { graphql, Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import PageHeader from "./PageHeader"
import { useTranslation } from "react-i18next"
import Card from "../components/Card"
import WallpaperFooterLink from "../components/WallpaperFooterLink"
import Breadcrumbs from "../components/Breadcrumbs"
import { WallpaperDesc } from "../graphql/SmallQueries"
import PageTitle from "../components/PageTitle"

const CategoryTemplate = ({
  data: {
    allAirtable: { nodes },
  },
}) => {
  const { i18n } = useTranslation() // 20210423
  const currentLanguage = i18n.language
  // console.log("this is category templates")
  const productType = WallpaperDesc()[0].name

  // console.log(nodes)

  const path = nodes[0]?.data ?? undefined
  const nameEn = path?.nameEn ?? undefined
  const nameMn = path?.nameMn ?? undefined
  const name = currentLanguage === "mn" ? nameMn : nameEn

  const textMn = path?.textMn ?? undefined
  const textEn = path?.textEn ?? undefined
  const text = currentLanguage === "mn" ? textMn : textEn

  const sharpPath = path?.Image ?? undefined
  const sharp = sharpPath ? getImage(sharpPath.localFiles[0]) : undefined
  const image = sharp ? (
    <GatsbyImage image={sharp} alt={name} className="w-full" />
  ) : undefined

  const wallpath = path?.Wallpaper ?? undefined
  const wallpapers = wallpath
    ? wallpath.map((item, index) => {
        const type = "wallpaper"
        const subpath = item?.data ?? undefined
        const name = subpath?.Name ?? undefined

        const productType = "wallpaper"

        const saleBeforeConverted = subpath?.Sale ?? undefined
        const sale = saleBeforeConverted
          ? saleBeforeConverted * 100 + "%"
          : undefined

        const newProduct = subpath?.New ?? undefined
        const hot = subpath?.Hot ?? undefined
        const modern = subpath?.Modern ?? undefined
        const best = subpath?.Bestseller ?? undefined

        const sharpPath = subpath?.Image ?? undefined
        const sharp = sharpPath ? getImage(sharpPath.localFiles[0]) : undefined
        const image = sharp ? (
          <GatsbyImage image={sharp} alt={name} className="w-full" />
        ) : undefined

        const slug = name ? name.trim().replace(/\s+/g, "-").toLowerCase() : "#"

        return {
          name,
          image,
          slug,
          type,
          productType,
          sale,
          newProduct,
          hot,
          modern,
          best,
        }
      })
    : undefined

  // console.log(wallpapers)
  const sortedWalls = wallpapers
    ? wallpapers.sort(function (a, b) {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      })
    : undefined

  // console.log(sortedWalls)

  const cards = sortedWalls?.map((item, index) => {
    return <Card key={index} data={item} />
  })

  return (
    <Layout>
      {/* <PageHeader title={name} text={text} /> */}
      <PageTitle title={name} />
      <div className="relative z-10 max-w-screen-lg mx-auto my-10 md:shadow-xl md:bg-white md:p-10">
        {/* ############## BREADCRUMP ################ */}

        <Breadcrumbs
          productType={productType}
          nameEn="wallpaper"
          productName={name}
        />
        {/* <div className="content-start pb-6">
          <Link to="/" className="text-gray-500 hover:text-gray-900">
            Home
          </Link>{" "}
          /{" "}
          <Link to="/wallpaper" className="text-gray-500 hover:text-gray-900">
            Wallpaper
          </Link>{" "}
          / {name}
        </div> */}

        <div className="grid grid-cols-2 gap-4 py-4 mx-2 md:grid-cols-4 sm:grid-cols-3 md:mx-0">
          {cards}
        </div>
        {/* <WallpaperFooterLink /> */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GetOntslogCategory($categoryId: String!) {
    allAirtable(
      filter: {
        table: {
          in: [
            "Wallpaper Angilal"
            "Wallpaper Ontslog"
            "Room"
            "Pattern"
            "Luxury"
          ]
        }
        id: { eq: $categoryId }
      }
    ) {
      nodes {
        data {
          nameEn: en
          nameMn: mn
          textMn: Mongol_tailbar
          textEn: English_tailbar
          Image {
            localFiles {
              childImageSharp {
                gatsbyImageData(width: 1000, layout: CONSTRAINED)
              }
            }
          }
          Wallpaper {
            data {
              Name
              Bestseller
              Hot
              New
              Modern
              Sale
              Image {
                localFiles {
                  childImageSharp {
                    gatsbyImageData(width: 400, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
        id
      }
    }
  }
`

export default CategoryTemplate
